<template>
    <v-container class="d-flex flex-column align-center flex-grow-1">
        <div class="d-flex flex-column align-center flex-grow-1 mt-12">
            <div class="text-h5 mb-10 center-text">The voiceform is not published!</div>
            <v-icon :size="65" color="primary">mdi-eye-off</v-icon>
            <div class="mt-10 text-body-1 center-text" style="max-width: 500px;">It looks like this survey has not been published. Please contact the voiceform creator to let them know.</div>
            <v-btn :loading="loading" :disabled="loading" class="mt-10" width="100%" max-width="300px" elevation="0" color="primary" @click="$emit('retry')">
                Try Again
            </v-btn>
        </div>
        <div class="d-flex flex-grow-0 mb-12 justify-center center-text">
            <powered-by  large />
        </div>
    </v-container>
</template>
<script>
import PoweredBy from './PoweredBy.vue'
export default {
  components: { PoweredBy },
  props: {
    loading: Boolean,
  }
    
}
</script>

<style lang="scss" scoped>
    .container{
        height: 100%
    }
    .center-text{
        text-align: center;
    }
</style>