<template>
    <v-container class="d-flex flex-column align-center flex-grow-1">
        <div class="d-flex flex-column align-center flex-grow-1 mt-12">
            <div class="text-h5 mb-10">Thanks for stopping by!</div>
            <v-icon :size="65" color="primary">mdi-file-question-outline</v-icon>
            <div class="mt-10 text-body-1 center-text" style="max-width: 500px;">Oops! It seems like this form is currently empty with no questions or fields to fill out. We apologize for the inconvenience. To rectify this, kindly reach out to the creator of this form and inform them about the issue. Thank you for your understanding and cooperation!</div>
        </div>
        <div class="d-flex flex-grow-0 mb-12 justify-center center-text">
            <powered-by large />
        </div>
    </v-container>
</template>
<script>
import PoweredBy from './PoweredBy.vue'
export default {
  components: { PoweredBy },
    
}
</script>

<style lang="scss" scoped>
    .container{
        height: 100%
    }
    .center-text{
        text-align: center;
    }
</style>