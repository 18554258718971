<template>
    <div>
        <v-app-bar v-if="!hideFullBar" :dark="styles.dark" height="62" class="header" absolute elevation="0" :style="headerStyles">
            <v-progress-linear v-if="!hideProgressBar" :color="styles.button.background" top fixed :value="answersCompletion"></v-progress-linear>
            <v-container class="pa-0">
                <v-row no-gutters align="center">
                    <div class="d-flex flex-0">
                        <top-left-image-logo/>
                    </div>
                    <v-spacer></v-spacer>
                    <v-fade-transition>
                        <div v-if="!hideProgressBar && showQuestionsCounter" class="text-body-2 text-sm-body-1 header-questions-counter">
                            <span v-if="hasLogic || survey.settings.percentage_progress">{{ answersCompletion }}% completed</span>
                            <span v-else>{{ questionNumber }}/{{ questionSteps.length }} questions</span>
                        </div>
                    </v-fade-transition>
                </v-row>
            </v-container>
        </v-app-bar>
        <!-- TODO align to the grid -->
        <v-snackbar
            top
            :value="error.show"
            color="error darken-1"
            left
            class="snackbar"
            :timeout="error.timeout"
        >
            {{error.message || 'Ups. Something went wrong.'}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    @click="handleSnackbarClose"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import TopLeftImageLogo from './TopLeftImageLogo.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'


export default {
    components: {TopLeftImageLogo },
    computed: {
        ...mapGetters({
            survey: 'form/survey',
            styles: 'form/styles',
            activeStepNumber: 'form/activeStepNumber',
            steps: 'form/steps',
            activeStep: 'form/activeStep',
            questionSteps: 'form/questionSteps',
            answers: 'form/answers',
            error: 'form/error',
            submissionStatus: 'form/submissionStatus',
            hasLogic: 'form/hasLogic',
            loopLists: 'form/loopLists'
        }),

        hideFullBar(){
            return !this.survey.top_left_file_url && this.hideProgressBar
        },

        hideProgressBar() {
            return this.survey && this.survey.settings && this.survey.settings.is_progressbar_hidden;
        },

        showQuestionsCounter(){
            return this.activeStep.component === 'QuestionStep' && !this.submissionStatus
        },

        questionNumber(){
            const index = this.questionSteps.findIndex(qStep=> qStep.id === this.activeStep.id)
            return index + 1 
        },

        headerStyles(){
            return `color: ${this.styles.backgroundTextColor} ; background-color: ${this.styles.background}; border-bottom: ${this.styles.backgroundTextColor}33 solid 1px;`
        },
        answersCompletion() {
            const steps = this.questionSteps.filter(
                question => question.type !== 'description-slide' && !question.skippedByLogic
            );
            const stepsWithoutLoops = steps.filter(step => !step.loopIndex)
            const numberOfLoops = this.loopLists && Object.values(this.loopLists).reduce((acc, loop) => {
                if(loop.length > 1){
                    return acc + loop.length - 1;
                }
                return acc;
            }, 0) || 0;
            const NumAnsweredQuestions = steps.reduce((prev, current) => {
                return (this.answers[current.id] && this.answers[current.id].answer) ? prev + 1 : prev;
            }, 0);
            return Math.round(NumAnsweredQuestions / (stepsWithoutLoops.length+numberOfLoops) * 100);
        }
    },
    watch: {
        answersCompletion(value){
            const roundedValue = this.getRoundedCompletion(value);
            this.updateDocumentWithData({
                answersCompletion: value,
                roundedCompletion: roundedValue
            })
        }
    },
    methods: {
        ...mapMutations({
            setError: 'form/setError',
        }),
        ...mapActions({
            updateDocumentWithData: 'form/updateDocumentWithData'
        }),
        handleSnackbarClose(){
            this.setError({show: false})
        },
        getRoundedCompletion(value) {
            // Ensure the value is within 0 and 100
            if (value < 0) return 0;
            if (value > 100) return 100;

            // Round to the nearest 10
            return Math.round(value / 10) * 10;
        }
    }
}
</script>

<style lang="scss" scoped>
.header{
    &-questions-counter{
        text-align: right;
    }
}
.snackbar-wrapper {
    position: absolute;
}
.snackbar{
    top: 62px;
}
</style>
