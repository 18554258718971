import { render, staticRenderFns } from "./AudioPermissions.vue?vue&type=template&id=14aef2cf&scoped=true&"
import script from "./AudioPermissions.vue?vue&type=script&lang=js&"
export * from "./AudioPermissions.vue?vue&type=script&lang=js&"
import style0 from "./AudioPermissions.vue?vue&type=style&index=0&id=14aef2cf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14aef2cf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VContainer,VFadeTransition,VNavigationDrawer})
