<template>

    <img
        v-if="survey.top_left_file_url"
        height="40px"
        class="logo-image"
        :src="survey.top_left_file_url"
    />
</template>

<script>

import {mapGetters} from 'vuex'


export default {
    name: 'TopLeftImageLogo',
    computed: {
        ...mapGetters({
            survey: 'form/survey'
        }),
        imageSource: () => this.survey && this.survey.top_left_file_url
    }
}
</script>

<style scoped>
 .logo-image{
     width: unset;
     height: 40px
 }
</style>
