<template>
    <div class="d-flex flex-grow-1 root" style="height: 100%">
        <survey-header></survey-header>
        <v-sheet :dark="styles.dark" class="main" :style="mainStyles">
            <v-dialog width="500" persistent :value="true">
                <v-card outlined class="pt-4 px-3" max-width="500" >
                    <v-card-text class="text-center" style="color: rgba(0, 0, 0, 1);">
                        <v-icon color="primary" class="mb-3" size="40">mdi-file-restore</v-icon>
                        <h5 class="text-h6 text-center">Looks like you have a form in progress!</h5>
                        <div class="mt-4 text-body-1">Would you like to pick up where you left off?</div>
                        <div class="d-flex flex-column" style="align-items: center;">
                            <v-btn color="primary" @click="resume" class="mt-8" width="200" elevation="0">Continue Form</v-btn>
                            <v-btn color="light" @click="restart" class="mt-3" width="200" elevation="0">Start Over</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-sheet>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SurveyHeader from './components/SurveyHeader.vue';
import { removeSurveyFromLocalStorage, getSurveyFromLocalStorage } from '../../utils/formLocalStorage';

export default {
    name: 'RestoreSurveyDialog',
    components: { SurveyHeader },
    props: {
        survey: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            styles: 'form/styles',
            hideFooter: 'form/hideFooter'
        }),
        mainStyles(){
            return `color: ${this.styles.backgroundTextColor} ; background-color: ${this.styles.background};}`
        },
    },
    methods: {
        ...mapActions({
            // restoring actions
            resumeFromLocalStorage: 'form/resumeFromLocalStorage',
            resumePartialResponse: 'form/resumePartialResponse',
            restartForm: 'form/restartForm'
        }),
        resume() {
            if(this.survey && this.survey.fsDoc){
                this.resumePartialResponse(this.survey.fsDoc)
            }else{
                const survey = getSurveyFromLocalStorage(this.survey.id);
                this.resumeFromLocalStorage({ ...survey });
            }
        },
        restart() {
            this.restartForm();
            removeSurveyFromLocalStorage(this.survey.id);
        },
    }
}
</script>

<style lang="scss" scoped>
.root{
        height: 100%
    }
.main{
    padding-top: 62px;
    width: 100%;
    height: 100%;
}
.slide{
    overflow: scroll;
}
</style>